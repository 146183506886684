import pt from "./locales/translate.pt.json";
import i18n from "i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

const options = {
  lng: "pt",
  interpolation: {
    escapeValue: false, // not needed for react!!
  },

  debug: false,
  fallbackLng: "pt",

  resources: {
    pt: {
      common: pt,
    },
    // en: {
    //   common: en["en_US"],
    // },
  },

  ns: ["common"],

  defaultNS: "common",

  react: {
    useSuspense: false,
    bindI18n: "languageChanged loaded",
    bindStore: "added removed",
    nsMode: "default",
  },
};

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init(options);

export default i18n;
