import React from "react";
import classes from "./Spinner.module.scss";

interface Interface {
  className?: string;
  small?: boolean;
  extraSmall?: boolean;
  center?: boolean;
  centerAbsolute?: boolean;
}

const Spinner = ({
  className = "",
  small = false,
  extraSmall = false,
  center = false,
  centerAbsolute = false,
}: Interface) => {
  let finalClass = className + " " + classes.SpinnerArea;
  if (small) finalClass += " " + classes.Small;
  if (extraSmall) finalClass += " " + classes.ExtraSmall;
  if (center) finalClass += " " + classes.Center;
  if (centerAbsolute) finalClass += " " + classes.CenterAbsolute;

  return (
    <div className={finalClass}>
      <div className={classes.Spinner}></div>
    </div>
  );
};

export default Spinner;
