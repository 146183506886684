import JSON_DICOTICOS_OE from "../assets/jsons/tests/dicotico_digitos_oe.json";
import JSON_DICOTICOS_OD from "../assets/jsons/tests/dicotico_digitos_od.json";
import JSON_DICOTICOS_OEOD from "../assets/jsons/tests/dicotico_digitos_oeod.json";
import JSON_FIGURA_FUNDO_OD from "../assets/jsons/tests/figura_fundo_od.json";
import JSON_FIGURA_FUNDO_OE from "../assets/jsons/tests/figura_fundo_oe.json";
import JSON_LOCALIZACAO_SONORA from "../assets/jsons/tests/localizacao.json";
import JSON_ORDENACAO_TEMPORAL from "../assets/jsons/tests/ordenacao_temporal.json";
import JSON_FECHAMENTO_OE from "../assets/jsons/tests/fechamento_oe.json";
import JSON_FECHAMENTO_OD from "../assets/jsons/tests/fechamento_od.json";
import JSON_RESOLUCAO_TEMPORAL from "../assets/jsons/tests/resolucao_temporal.json";
import JSON_DICOTICO_SEQUENCIAL from "../assets/jsons/tests/dicotico_sequencial.json";

export const GET_AUDIO_DIR = (type: string, file: string) =>
  `/tests/audios/${type}/${file}`;

export const DICOTICOS_OE = "dicoticos_oe";
export const DICOTICOS_OD = "dicoticos_od";
export const DICOTICOS_OEOD = "dicoticos_oeod";
export const FIGURA_FUNDO_OD = "figura_fundo_od";
export const FIGURA_FUNDO_OE = "figura_fundo_oe";
export const LOCALIZACAO_SONORA = "localizacao_sonora";
export const ORDENACAO_TEMPORAL = "ordenacao_temporal";
export const FECHAMENTO_OE = "fechamento_oe";
export const FECHAMENTO_OD = "fechamento_od";
export const RESOLUCAO_TEMPORAL = "resolucao_temporal";
export const DICOTICO_SEQUENCIAL = "dicotico_sequencial";

export const LIST_TESTS = [
  DICOTICOS_OD,
  DICOTICOS_OE,
  DICOTICOS_OEOD,
  FIGURA_FUNDO_OD,
  FIGURA_FUNDO_OE,
  LOCALIZACAO_SONORA,
  ORDENACAO_TEMPORAL,
  FECHAMENTO_OE,
  FECHAMENTO_OD,
  RESOLUCAO_TEMPORAL,
  DICOTICO_SEQUENCIAL,
];
export const MAP_JSON_TEST = {
  [DICOTICOS_OE]: JSON_DICOTICOS_OE,
  [DICOTICOS_OD]: JSON_DICOTICOS_OD,
  [DICOTICOS_OEOD]: JSON_DICOTICOS_OEOD,
  [FIGURA_FUNDO_OD]: JSON_FIGURA_FUNDO_OD,
  [FIGURA_FUNDO_OE]: JSON_FIGURA_FUNDO_OE,
  [LOCALIZACAO_SONORA]: JSON_LOCALIZACAO_SONORA,
  [ORDENACAO_TEMPORAL]: JSON_ORDENACAO_TEMPORAL,
  [FECHAMENTO_OE]: JSON_FECHAMENTO_OE,
  [FECHAMENTO_OD]: JSON_FECHAMENTO_OD,
  [RESOLUCAO_TEMPORAL]: JSON_RESOLUCAO_TEMPORAL,
  [DICOTICO_SEQUENCIAL]: JSON_DICOTICO_SEQUENCIAL,
} as { [v: string]: any };
