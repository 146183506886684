import React, { useContext, useEffect, useState } from "react";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import * as r from "../../../constants/routes";
import classes from "./MainNav.module.scss";
import LoginContext from "../../../context/loginContext";
import logo from "../../../assets/images/logo.png";
import SessionContext from "../../../context/sessionContext";
import { Avatar, Grid, IconButton } from "@material-ui/core";
import { Divider } from "@aws-amplify/ui-react";
import { Auth } from "aws-amplify";
import UserTrainingContext from "../../../context/userTrainingContext";

const MainNav = () => {
  const [isOpen, setIsOpen] = useState(false);
  const asideClass = isOpen ? classes.AsideNavOpen : "";
  const { t } = useTranslation();
  const cSession = useContext(SessionContext);
  const { is_manager } = cSession.admin ?? {};
  const cLogin = useContext(LoginContext);
  const tContext = useContext(UserTrainingContext);

  const plan_permission: string = localStorage.getItem("plan_permission") ?? "";
  useEffect(() => {}, [cSession.is_session_active]);


  const getLinkQuestion = (title: any, text: any) => {
    let mail = "mailto:audiofoco@probrain.com.br";
    mail += `?subject=Audiofoco - ${title}`;
    mail += `&body=Olá,%0D%0D ${text}.`;
    const auth: any = Auth;
    if (
      Auth &&
      auth.user &&
      auth.user.attributes &&
      auth.user.attributes.email
    ) {
      mail += "%0D%0DE-mail cadastrado: '" + auth.user.attributes.email + "'.";
    } else {
      mail += "%0D%0DUsuário: '" + String(tContext.registry) + "'.";
      mail += `%0D%0AE-mail do Profissional:  ${
        cSession.user && cSession.user.professional_email
      }%0D%0A`;
    }
    return mail;
  };

  const getBtn = (
    to = "",
    text = "",
    title = "",
    onClick = () => {}
  ): { [k: string]: any; onClick?: () => void } => {
    if (title !== "") title = text;
    return (
      <li>
        <NavLink
          onClick={() => {
            setIsOpen(!isOpen);
            if (onClick) onClick();
          }}
          to={to}
          title={t(title)}
        >
          {t(text)}
        </NavLink>
      </li>
    );
  };

  const _li_a_login = getBtn(r.R_A_LOGIN, "global.login_to_admin");
  const _li_u_login = getBtn(r.R_U_LOGIN, "global.login_to_users");

  const _li_a_my_users_go = getBtn(
    r.R_A_USERS_LIST,
    "global.manage_go_all_users"
  );
  const _li_a_my_users_smart = getBtn(
    r.R_A_USERS_LIST_SMART,
    "global.manage_smart_all_users"
  );
  const _li_a_add_users_go = getBtn(r.R_A_USERS_ADD_GO, "global.add_users_go");
  const _li_a_add_users_smart = getBtn(
    r.R_A_USERS_ADD_SMART,
    "global.add_users_smart"
  );
  const _li_a_off_users_go = getBtn(
    r.R_A_USERS_OFF_GO,
    "global.disable_users_go"
  );
  const _li_a_off_users_smart = getBtn(
    r.R_A_USERS_OFF_SMART,
    "global.disable_users_smart"
  );
  const _li_a_support_ = (
    <a
      href={getLinkQuestion("Suporte", "Estou com a seguinte dúvida")}
      target="_blank"
      rel="noreferrer"
    >
      Suporte Técnico
    </a>
  );
  const _li_a_suggestion = (
    <a
      href={getLinkQuestion(
        "Elogio / Sugestão",
        "Estou com o seguinte Elogio / Sugestão"
      )}
      target="_blank"
      rel="noreferrer"
    >
      Elogio / Sugestão
    </a>
  );
  const _li_a_help = (
    <a
      href={getLinkQuestion(
        "Ajuda / Dúvida",
        "Estou preciso de Ajuda / Dúvida"
      )}
      target="_blank"
      rel="noreferrer"
    >
      Ajuda / Dúvida
    </a>
  );
  const _li_a_complaint_ = (
    <a
      href={getLinkQuestion("Reclamação", "Estou com a seguinte reclamação")}
      target="_blank"
      rel="noreferrer"
    >
      Reclamação
    </a>
  );
  const mapPermissionBtn: { [index: string]: string } = {
    "01": "go",
    "10": "smart",
    "11": "smart_go",
  };
  const getBtnsByPlanPermission = (planPermission: string) => {
    const permission: string = mapPermissionBtn[String(planPermission)];

    if (permission === "go") {
      return (
        <>
          {_li_a_my_users_go}
          {_li_a_add_users_go}
          {_li_a_off_users_go}
        </>
      );
    } else if (permission === "smart") {
      return (
        <>
          {_li_a_my_users_smart}
          {_li_a_add_users_smart}
          {_li_a_off_users_smart}
        </>
      );
    } else if (permission === "smart_go") {
      return (
        <>
          {_li_a_my_users_go}
          {_li_a_my_users_smart}
          {_li_a_add_users_smart}
          {_li_a_add_users_go}
          {_li_a_off_users_smart}
          {_li_a_off_users_go}
        </>
      );
    } else {
      return <></>;
    }
  };

  const _li_a_my_pro = getBtn(r.R_A_PRO_LIST, "global.manage_all_admin");
  const _li_a_add_pro = getBtn(r.R_A_PRO_ADD, "global.add_admins");
  // const _li_a_off_pro = getBtn(r.R_A_PRO_OFF, "global.disable_admins");

  const _li_a_update_pw = getBtn(r.R_A_UPDATE_PW, "global.change_password");
  // const _li_a_tests = getBtn(r.R_U_TESTS, "global.tests");

  /* USER */
  const _li_u_training = getBtn(r.R_U_HOME, "global.training");
  // Logouts
  const _li_logout = getBtn(
    r.R_U_ROOT,
    "global.logout",
    "global.logout",
    () => {
      cSession.sessionLogout();
      cLogin.setLogout();
    }
  );

  const _terms_of_use = getBtn(
    r.R_U_TERMS,
    "global.terms_of_use",
    "global.terms_of_use",
    () => {
      return window.open(
        "https://termos.probrain.com.br/pub/terms/terms_2022.pdf"
      );
    }
  );
  const _policies_and_privileges = getBtn(
    r.R_U_TERMS,
    "global.policies_and_privileges",
    "global.policies_and_privileges",
    () => {
      return window.open(
        "https://termos.probrain.com.br/pub/policies/policies_2022.pdf"
      );
    }
  );
  const security_policies = getBtn(
    r.R_U_TERMS,
    "global.security_privileges",
    "global.security_privileges",
    () => {
      return window.open(
        "https://www.audbility.com.br/_files/ugd/cd0d44_f01a8753e2e647429e39e9bdb3ada60b.pdf"
      );
    }
  );

  // ADD DEFAULT ITEMS ON ASIDE NAV

  return (
    <div className={asideClass + " " + classes.MainNavArea}>
      <header className={classes.MainNav}>
        <div className={"container-fluid"}>
          <div className="row">
            <div className={classes.InnerArea}>
              <NavLink
                to={
                  !cSession.is_session_active
                    ? r.R_U_ROOT
                    : cSession.is_admin
                    ? r.R_A_HOME
                    : r.R_U_ROOT
                }
              >
                <figure>
                  <img src={logo ?? ""} alt={""}></img>
                </figure>
              </NavLink>
              <div className={classes.Spacer} />
              <IconButton
                className={classes.BtnOpenAside}
                onClick={() => setIsOpen(!isOpen)}
              >
                <FontAwesomeIcon icon={isOpen ? faTimes : faBars} />
              </IconButton>
            </div>
          </div>
        </div>
      </header>
      <aside className={classes.AsideNav}>
        <nav>
          <ul>
            {/* =========== ADMIN =========== */}
            {cSession.admin && cSession.admin.email ? (
              <>
                <li>
                  <Grid
                    className={classes.profile}
                    container
                    spacing={2}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Grid item xs={12}>
                      <Avatar className={classes.primary}>
                        {cSession.admin.email.charAt(0)}
                      </Avatar>
                    </Grid>
                    <Grid item xs={12}>
                      <span>{cSession.admin.email}</span>
                    </Grid>
                  </Grid>
                  {/* <span className={classes.profile}>
                  
                 
                </span> */}
                </li>
                <Divider />
              </>
            ) : null}

            <div className={classes.menuContain}>
              {cSession.is_admin && (
                <>
                  <li className={classes.NavLabel}>
                    <p>{t("global.users")}</p>
                  </li>
                  {getBtnsByPlanPermission(plan_permission)}
                </>
              )}
              {is_manager && (
                <>
                  <li className={classes.NavLabel}>
                    <p>{t("global.admins")}</p>
                  </li>
                  {_li_a_my_pro}
                  {_li_a_add_pro}
                </>
              )}
              {cSession.is_admin && !cSession.is_einstein_user && (
                <>
                  <li className={classes.NavLabel}>
                    <p>{t("global.settings")}</p>
                  </li>
                  {_li_a_update_pw}
                  {/* {_li_a_tests} */}
                </>
              )}
              {/* ============ USER ============ */}
              {cSession.is_user && !cSession.is_smart_user && _li_u_training}
              {/* {cSession.is_user && _li_a_tests} */}
              {/* ============= ALL ============= */}
              {/* Login */}
              {(cSession.is_session_active || cLogin.first_access_res) &&
                _terms_of_use}
              {(cSession.is_session_active || cLogin.first_access_res) &&
                _policies_and_privileges}
              {(cSession.is_session_active || cLogin.first_access_res) &&
                security_policies}
              <li className={classes.NavLabel}>
                <p>{t("global.support")}</p>
              </li>
              <li>
                {(cSession.is_session_active || cLogin.first_access_res) &&
                  _li_a_complaint_}
              </li>
              <li>
                {(cSession.is_session_active || cLogin.first_access_res) &&
                  _li_a_suggestion}
              </li>
              <li>
                {(cSession.is_session_active || cLogin.first_access_res) &&
                  _li_a_help}
              </li>
              <li>
                {(cSession.is_session_active || cLogin.first_access_res) &&
                  _li_a_support_}
              </li>
              {/* Logout */}
              {(cSession.is_session_active || cLogin.first_access_res) &&
                _li_logout}
              {!cSession.is_session_active && !cLogin.first_access_res && (
                <>
                  {_li_a_login}
                  {_li_u_login}
                </>
              )}
            </div>
          </ul>
        </nav>
      </aside>
      <div onClick={() => setIsOpen(!isOpen)} className={classes.OutsideArea}>
        <div />
      </div>
    </div>
  );
};
export default MainNav;
