import React, { useContext, useState, useEffect } from "react";
import classes from "./Login.module.scss";
import favicon from "../../../assets/images/favicon.png";
import InputText from "../../../components/Inputs/InputText";
import * as h from "../../../helpers";
import RoundButton from "../../../components/Buttons/RoundButton";
import Spinner from "../../../components/Spinner";
import * as d from "../../../constants/defaults";
import { useHistory, useLocation } from "react-router";
import LoginContext from "../../../context/loginContext";
import Alert from "../../../components/Alerts";
import { R_A_USERS_LIST } from "../../../constants/routes";
import { useTranslation } from "react-i18next";
import AlertDialog from "../../../components/AlertDialog";

export default function Login() {
  // #01 Hooks
  const context = useContext(LoginContext);
  const location = useLocation();
  const { t } = useTranslation();
  const history = useHistory();

  // #02 Startups
  const { company_uid } = h.getQueryStringParams(location.search);
  // #03 States
  const [pw, setPw] = useState("");

  const loginActionHandler = (event?: any) => {
    context.authSignInHandler({
      company_uid: company_uid,
      event: event,
      pw: pw,
      history,
      route: R_A_USERS_LIST,
    });
  };


  return (
    <div className={classes.Login}>
      <form
        className={classes.TagCard}
        onSubmit={(e) => {
          e.preventDefault();
          loginActionHandler(e);
        }}
      >
        <div
          className={classes.Logo}
          style={{ backgroundImage: `url(${favicon})` }}
        />
        <h1 className={classes.Title}>{t("global.welcome")}!</h1>
        <p className={classes.Subtitle}>
          {t("global.access_your_account_below")}!
        </p>
        <InputText
          label={t("global.email")}
          fake={context.loading}
          onChange={context.setUsername}
          required={true}
          type={d.EMAIL}
          value={context.username}
        />
        <InputText
          fake={context.loading}
          label={t("global.password")}
          onChange={setPw}
          required
          type={d.PASSWORD}
          value={pw}
          showPWIcon
        >
          <a className={classes.SmallLinkLeft} href="/redefinicao_senha">
            {t("global.forgot_password")}
          </a>
        </InputText>
        <AlertDialog
          textButton={t("global.do_you_need_help")}
          isConfirm={false}
          isInfo={true}
          isButtonText={true}
          textDialogPrimary={t("global.do_you_need_help")}
          textDialogSecondary={t("global.do_you_need_help_info1")}
        />
        <br />
        {/* <a className={classes.NeedHelp} href="%PUBLIC_URL%/favicon.ico">
          {t("global.do_you_need_help")}
        </a> */}
        {context.msg_error && !context.loading && (
          <Alert text={context.msg_error} danger />
        )}
        {context.loading ? (
          <Spinner center />
        ) : (
          <RoundButton
            centerBottom
            huge
            type={d.SUBMIT}
            text={t("global.enter")}
            title={t("global.press_to_enter")}
          />
        )}
      </form>
      <div className={classes.termsContainer}>
        <a
          className={classes.termsLogin}
          href="https://termos.probrain.com.br/pub/policies/policies_2022.pdf"
          target="_blank"
          rel="noreferrer"
        >
          {t("global.policies_and_privileges")}
        </a>
        <> - </>
        <a
          className={classes.termsLogin}
          href="https://www.audbility.com.br/_files/ugd/cd0d44_f01a8753e2e647429e39e9bdb3ada60b.pdf"
          target="_blank"
          rel="noreferrer"
        >
          {t("global.security_privileges")}
        </a>
        <> - </>
        <a
          className={classes.termsLogin}
          href="https://termos.probrain.com.br/pub/terms/terms_2022.pdf"
          target="_blank"
          rel="noreferrer"
        >
          {t("global.terms_of_use")}
        </a>
      </div>
    </div>
  );
}
