import React, { useState } from "react";
import {
  faEye,
  faEyeSlash,
  faStarOfLife,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { EMAIL, PASSWORD, TEXT } from "../../../constants/defaults";
import classes from "./InputText.module.scss";
import { useTranslation } from "react-i18next";

interface Interface {
  label: string;
  value: string;
  // not required
  onChange?: any;
  type?: string;
  children?: any;
  name?: string;
  alt?: string;
  className?: string;
  onClickShowPw?: any;
  required?: boolean;
  showPw?: boolean;
  showPWIcon?: boolean;
  fake?: boolean;
  maxNumber?: string | undefined;
  minNumber?: string | undefined;
}

export default function InputText({
  type = TEXT,
  onChange = null,
  children = null,
  label = "",
  name = "",
  value = "",
  alt = "",
  className = "",
  onClickShowPw = null,
  required = false,
  showPw = false,
  showPWIcon = false,
  fake = false,
  maxNumber = undefined,
  minNumber = undefined,
}: Interface) {
  const { t } = useTranslation();
  const [isFocus, setFocus] = useState(false);
  const [innerShowPw, setInnerShowPw] = useState(showPw);

  const placeholder = label;
  className += " " + classes.InputArea;
  if (isFocus) className += " " + classes.InFocus;

  const isVisible = showPw || innerShowPw;

  const _stars = [];
  for (let i = 0; i < 10; i++)
    _stars.push(<FontAwesomeIcon key={i} icon={faStarOfLife} />);

  // PASSWORD btn eye/eyeSlash
  let _showPassword = null;
  if (type === PASSWORD) {
    type = isVisible ? TEXT : PASSWORD;
    if (showPWIcon && !fake)
      _showPassword = (
        <button
          type="button"
          tabIndex={0}
          title={t("global.show_pw")}
          onClick={(e) => {
            e.preventDefault();
            if (onClickShowPw) onClickShowPw(!showPw);
            setInnerShowPw(!innerShowPw);
          }}
          className={classes.BtnShowPW}
        >
          <FontAwesomeIcon icon={isVisible ? faEye : faEyeSlash} />
        </button>
      );
  }

  const onChangeHandler = (e: any) => {
    let newValue = e.target.value;
    if (type === EMAIL) {
      newValue = newValue.trim();
      newValue = newValue.toLowerCase();
      newValue = newValue.replace(/\s+/g, "");
    }
    if (onChange) {
      if (type === "number") {
        newValue = newValue.replace(/\D+/g, "");
      }
      onChange(newValue);
    }
  };
  let classInput = classes.Input;
  if (showPWIcon) classInput += " " + classes.ShowBtn;
  return (
    <div className={className}>
      <div className={isFocus ? classes.WrapperFocus : classes.Wrapper}>
        {fake ? (
          <p
            className={classInput}
            title={type === PASSWORD ? "" : value}
            placeholder={isFocus ? "" : t(placeholder)}
          >
            <span>{type === PASSWORD ? _stars : value}</span>
          </p>
        ) : (
          <input
            className={classInput}
            value={value}
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
            onChange={onChangeHandler}
            type={type}
            required={required}
            name={name}
            alt={alt}
            title={alt}
            max={maxNumber}
            min={minNumber}
            placeholder={isFocus ? "" : t(placeholder)}
          />
        )}
        {_showPassword}
      </div>
      {((label && isFocus) || value !== "") && <label>{t(label)}</label>}
      {children}
    </div>
  );
}
