import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import classes from "./Alert.module.scss";

interface Interface {
  text: any;
  onClose?: any;
  mt?: any;
  absolute?: any;
  closeBtn?: any;
  success?: any;
  warning?: any;
  danger?: any;
}

const Alert = ({
  onClose = null,
  text = null,
  closeBtn = true,
  absolute = null,
  mt = false,
  success = false,
  warning = false,
  danger = false,
}: Interface) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(true);
  let alert = null;
  if (text && text !== "" && show) {
    let finalClass = classes.Info;
    if (success) finalClass = classes.Success;
    if (warning) finalClass = classes.Warning;
    if (danger) finalClass = classes.Danger;
    if (mt) finalClass += " " + classes.Mt;
    if (absolute) finalClass += " " + classes.Absolute;

    alert = (
      <div className={classes.Alert + " " + finalClass} role="alert">
        {t(text)}
        {closeBtn && (
          <button
            type="button"
            className={classes.Close}
            onClick={() => {
              if (onClose) onClose();
              setShow(false);
            }}
            data-dismiss="alert"
            aria-label={t("global.close")}
          >
            <span aria-hidden="true">×</span>
          </button>
        )}
      </div>
    );
  }
  return alert;
};

export default Alert;
