import React, { createContext, useContext, useReducer } from "react";
import Auth from "@aws-amplify/auth";
import * as awsMsg from "../constants/aws";
import * as r from "../constants/routes";
import * as h from "../helpers";
import * as kAws from "../constants/aws";
import { EMAIL_TEMP_FAKE_CHANGE_EMPTY_STRING } from "../constants/defaults";
import SessionContext from "./sessionContext";
import { useTranslation } from "react-i18next";
import * as api from "../constants/api";
import axios from 'axios';

interface BaseRequestInterface {
  event: Event | null;
  history: any;
  pw: string;
  route?: string;
}
interface FuncCompleteLoginInterface extends BaseRequestInterface {
  // event: Event | null;
  // history: any;
  // pw: string;
  // route: string;
  required_att?: {
    [propName: string]: any;
    [kAws.CUSTOM_COMPANY_UID]: string;
    [kAws.CUSTOM_IS_MANAGER]: any;
  };
}
interface AuthSignInHandlerInterface extends BaseRequestInterface {
  // event: Event | null;
  // history: any;
  // pw: string;
  // route: string;
  company_uid?: any;
}

interface InitialStateInterface {
  loading: boolean;
  msg_error: string | null;
  company_uid: string;
  username: string;
  first_access_res: boolean;
}

interface LoginInterface extends InitialStateInterface {
  setCompanyName: (v: string) => void;
  setUsername: (v: string) => void;
  setLoading: (v: boolean) => void;
  setErrorMsg: (v: string) => void;
  setLogout: () => void;
  authSignInHandler: (v: AuthSignInHandlerInterface) => void;
  completeLoginHandler: (v: FuncCompleteLoginInterface) => void;
}
const LoginContext = createContext({} as LoginInterface);

const TYPE_STORE_COMPANY_NAME = "TYPE_STORE_COMPANY_NAME";
const TYPE_STORE_USERNAME = "TYPE_STORE_USERNAME";
const TYPE_LOADING = "TYPE_LOADING";
const TYPE_MSG_ERROR = "TYPE_MSG_ERROR";
const TYPE_SET_FIRST_ACCESS_RES = "TYPE_SET_FIRST_ACCESS_RES";
const TYPE_SET_RESET = "TYPE_SET_RESET";

const reducer = (state: InitialStateInterface, action: any) => {
  switch (action.type) {
    case TYPE_STORE_COMPANY_NAME:
      return {
        ...state,
        company_uid: action.company_uid,
      };
    case TYPE_STORE_USERNAME:
      return {
        ...state,
        username: action.username,
      };
    case TYPE_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case TYPE_MSG_ERROR:
      return {
        ...state,
        msg_error: action.msg_error,
      };
    case TYPE_SET_FIRST_ACCESS_RES:
      return {
        ...state,
        first_access_res: action.first_access_res,
      };
    case TYPE_SET_RESET:
      return {
        ...action.initial_state,
      };

    default:
      return state;
  }
};

export const LoginProvider = ({ children }: any) => {
  const cSessions = useContext(SessionContext);

  const initialState: InitialStateInterface = {
    loading: false,
    msg_error: null,
    company_uid: "",
    username: EMAIL_TEMP_FAKE_CHANGE_EMPTY_STRING,
    first_access_res: false,
  };

  const { t } = useTranslation();
  const [state, dispatch] = useReducer(reducer, initialState);

  // -----------------------------
  //            ACTIONS
  // -----------------------------
  const setCompanyName = (company_uid: string) => {
    dispatch({
      type: TYPE_STORE_COMPANY_NAME,
      company_uid,
    });
  };

  const setUsername = (username: string) => {
    dispatch({
      type: TYPE_STORE_USERNAME,
      username,
    });
  };

  const setLoading = (loading: boolean) => {
    dispatch({
      type: TYPE_LOADING,
      loading,
    });
  };

  const setErrorMsg = (msg_error: string | null) => {
    dispatch({
      type: TYPE_MSG_ERROR,
      msg_error,
    });
  };

  const setFirstAccessRes = (first_access_res: string | boolean) => {
    dispatch({
      type: TYPE_SET_FIRST_ACCESS_RES,
      first_access_res,
    });
  };

  const setLogout = () => {
    dispatch({
      type: TYPE_SET_RESET,
      initial_state: initialState,
    });
  };
  // -----------------------------
  //           HANDLERS
  // -----------------------------

  /**
   *
   * @description Auth at Login Context
   * @param {company_uid, pw, event, history} param0
   * @default { company_uid = "",pw = "", event = null, history = null,}
   */
  const parseJwt = (token = "") => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  };
  const authSignInHandler = ({
    company_uid = null,
    event,
    pw = "",
    history,
    route = "",
  }: AuthSignInHandlerInterface) => {
    if (event) event.preventDefault();
    setLoading(true);
    const data = {
      username: state.username,
      password: pw
    };
    if (state.username.indexOf("einstein.br") > -1 || state.username === "arthur@probrain.com.br") {

      // Auth.configure({dataPost})
      console.log("is einstein.br");

      axios(api.API_EINSTEIN, {
        data:
          "username=" + data.username +
          "&password=" + encodeURIComponent(data.password) +
          "&client_id=frontend" +
          "&grant_type=password",
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        }
      }
      ).then(res => {
        const _data_tmp = { ...res.data, username: data.username }
        axios(api.API_PARTNER_LOGIN, {
          data: _data_tmp,
          method: "POST",
          headers: { "Content-Type": "application/json" }
        }).then(responde_server => {
          if("AuthenticationResult" in responde_server.data) {
            console.log(responde_server.data);
            const acess = parseJwt(responde_server.data.AuthenticationResult.AccessToken);
            cSessions.setAdminUser({
              "custom:company_uid": "us-east-1_yA75JqsML_EinsteinIntegration",
              "custom:is_manager": true,
              "is_einstein_user": true,
              "email": data.username,
              "sub": acess.sub,
              "idToken": responde_server.data.AuthenticationResult.IdToken
            });
            setFirstAccessRes(false);
            if (history) history.push(r.R_A_HOME);
          } else {
            setErrorMsg(responde_server.data.message);
          }
        }).finally(() => {
          setLoading(false);
        });
      }).catch(e => {
        console.log(e);
        setErrorMsg("Falha ao autenticar, verifique com o administrador.");
        setLoading(false);
      })


    } else {
      Auth.signIn(data)
        .then((res) => {
          if (company_uid) setCompanyName(company_uid);
          const company_uid_cognito = res?.challengeParam?.userAttributes["custom:company_uid"] ? res?.challengeParam?.userAttributes["custom:company_uid"] : ''
          if (!company_uid && company_uid_cognito) setCompanyName(company_uid_cognito);
          if (res.challengeName === awsMsg.NEW_PASSWORD_REQUIRED) {
            setFirstAccessRes(res);
            if (history) history.push(r.R_A_FIRST_PW);
          } else {
            cSessions.setAdminUser({
              ...res.attributes,
            });
            setFirstAccessRes(false);
            if (history) history.push(r.R_A_HOME);
          }
        })
        .catch((error) => {
          console.error("[error Auth.signIn]", error);

          if (error.code) setErrorMsg(h.getLoginErrorMsg(error.code));
          else setErrorMsg(error.message);
          // if (errors.code === kUser.USER_NOT_CONFIRMED_EXCEPTION)
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  /**
   *
   * @param param0
   */
  const completeLoginHandler = ({
    route = r.R_A_USERS_LIST,
    event,
    history,
    pw = "",
    required_att = {
      [kAws.CUSTOM_COMPANY_UID]: state.company_uid,
      [kAws.CUSTOM_IS_MANAGER]: "true",
    },
  }: FuncCompleteLoginInterface) => {
    if (event) event.preventDefault();
    setLoading(true);
    // NOT LOGGED
    if (!state.first_access_res) {
      history.push(r.R_A_LOGIN);
      setErrorMsg(t("infos.you_must_be_logged"));
      setLoading(false);
    }

    if (state.first_access_res.challengeParam.userAttributes['custom:is_manager']) {
      Auth.completeNewPassword(state.first_access_res, pw)
        .then((response) => {
          authSignInHandler({ event, pw, history, route: r.R_A_HOME });
        })
        .catch((err) => {
          let tempMsg = null;
          if (err.message === awsMsg.TEMP_KEY_USED) {
            tempMsg = awsMsg.MAP_ERRORS_TO_SHOW[awsMsg.TEMP_KEY_USED];
          } else if (err.message === awsMsg.SESSION_EXPIRED) {
            tempMsg = awsMsg.MAP_ERRORS_TO_SHOW[awsMsg.SESSION_EXPIRED];
          }
          history.push(r.R_A_LOGIN);
          setErrorMsg(tempMsg);
          setLoading(false);
        });
    } else {
      Auth.completeNewPassword(state.first_access_res, pw, required_att)
        .then((response) => {
          authSignInHandler({ event, pw, history, route: r.R_A_HOME });
        })
        .catch((err) => {
          let tempMsg = null;
          if (err.message === awsMsg.TEMP_KEY_USED) {
            tempMsg = awsMsg.MAP_ERRORS_TO_SHOW[awsMsg.TEMP_KEY_USED];
          } else if (err.message === awsMsg.SESSION_EXPIRED) {
            tempMsg = awsMsg.MAP_ERRORS_TO_SHOW[awsMsg.SESSION_EXPIRED];
          }
          history.push(r.R_A_LOGIN);
          setErrorMsg(tempMsg);
          setLoading(false);
        });
    }
  };

  // -----------------------------
  //            RETURN
  // -----------------------------
  return (
    <LoginContext.Provider
      value={{
        ...state,
        // actions ---------------
        setCompanyName,
        setUsername,
        setLoading,
        setErrorMsg,
        setLogout,

        authSignInHandler,
        completeLoginHandler,
      }}
    >
      {children}
    </LoginContext.Provider>
  );
};
export default LoginContext;
