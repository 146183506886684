import * as defaults from "./constants/defaults";
import { environment } from "./build_environment";

// -----------------------
//       next-dev DEV
// -----------------------

//DEFAULT
const config_dev = {
  userPoolId: "us-east-1_kxIr7xkzN",
  region: "us-east-1",
  userPoolWebClientId: "5ucpl1udve2kfnbf1a70ihfhvl",
};

// -----------------------
//       next-dev PROD
// -----------------------

const config_prod = {
  userPoolId: 'us-east-1_y98DN8Ny1',
  region: 'us-east-1',
  userPoolWebClientId: '20voquk5vfn54okk3rf0ucqncj',
};

export const RUNNING_ENVIRONMENT = environment;

/* tslint:disable */
const config =
  String(environment) === String(defaults.DEV) ? config_dev : config_prod;
/* tslint:enable */

export default config;
