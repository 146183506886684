import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useTranslation } from "react-i18next";
import classes from "./Modal.module.scss";
import RoundButton from '../Buttons/RoundButton';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

const AlertDialog = (props: any) => {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const {
    textButton,
    textDialogPrimary,
    textDialogSecondary,
    onCancel,
    onConfirm,
    isConfirm = true,
    isCalendar = false,
    noButton = false,
    buttonDesabled = false,
    isInfo = false,
    printReport = false,
    isButtonText = false } = props;
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    if (onCancel) onCancel()
    setOpen(false);
  };
  const handleConfirm = () => {
    if (!noButton) setOpen(false);
    if (onConfirm) onConfirm();
    if (noButton) setOpen(true);
  };
  const infoIcon = <FontAwesomeIcon icon={faInfoCircle} />

  let showOnlyConfirmOption = true;
  if (!isConfirm) showOnlyConfirmOption = false;
  // let calendar = ''
  // if (isCalendar) calendar = 'CalendarIcon';
  if (!noButton) {
    return (
      <>
        {!isCalendar && !isInfo && (
          <RoundButton
            disabled={buttonDesabled}
            title={textButton}
            text={textButton}
            onClick={handleClickOpen}
          />
        )}
        {printReport && (
          <RoundButton
            small
            disabled={buttonDesabled}
            title={textButton}
            text={textButton}
            onClick={handleClickOpen}
          />
        )}
        {isCalendar && !isInfo && (
          <RoundButton
            calendarIcon
            disabled={buttonDesabled}
            faIcon={faCalendarAlt}
            title={``}
            small={true}
            huge
            onClick={handleClickOpen}
          />
        )}
        {isInfo && !isCalendar && !isButtonText && (
          <a onClick={handleClickOpen}>
            <sup>{infoIcon}</sup>
          </a>
        )}
        {isButtonText && !isCalendar && (
          <a onClick={handleClickOpen}>
            <p>{textButton}</p>
          </a>
        )}
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle
            id="alert-dialog-title"
            className={classes.TextDialogPrimary}
          >
            {textDialogPrimary}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {textDialogSecondary}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            {showOnlyConfirmOption && (
              <>
                <Button
                  onClick={handleClose}
                  className={classes.TextDialogButtons}
                >
                  {t("global.no")}
                </Button>
                <Button
                  style={{ border: "1px solid #007085" }}
                  onClick={handleConfirm}
                  className={classes.TextDialogButtons}
                  autoFocus
                  disabled={!open}
                >
                  {t("global.yes")}
                </Button>
              </>
            )}
            {!showOnlyConfirmOption && (
              <>
                <Button
                  onClick={handleConfirm}
                  className={classes.TextDialogButtons}
                  autoFocus
                >
                  {t("global.ok")}
                </Button>
              </>
            )}
          </DialogActions>
        </Dialog>
      </>
    );
  } else {
    return (
      <div>
        <Dialog
          open={!open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title" className={classes.TextDialogPrimary}>{textDialogPrimary}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {textDialogSecondary}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            {showOnlyConfirmOption &&
              <>
                <Button onClick={handleClose} className={classes.TextDialogButtons}>
                  {t('global.no')}
                </Button>
                <Button onClick={handleConfirm} className={classes.TextDialogButtons} autoFocus>
                  {t('global.yes')}
                </Button>
              </>}
            {!showOnlyConfirmOption &&
              <>
                <Button onClick={handleConfirm} className={classes.TextDialogButtons} autoFocus>
                  {t('global.ok')}
                </Button>
              </>}
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}


export default AlertDialog;