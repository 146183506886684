import React from "react";
import classes from "./PassWordValidations.module.scss";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";

interface Interface {
  hasMinEight: boolean;
  hasNumber: boolean;
  hasUppercase: boolean;
  hasLowercase: boolean;
  hasSamePW: boolean;
  className?: string | null;
}

export default function PassWordValidations({
  hasMinEight = false,
  hasNumber = false,
  hasUppercase = false,
  hasLowercase = false,
  hasSamePW = false,
  className = null,
}: Interface) {
  const { t } = useTranslation();
  let hasMinEightClass = classes.Error;
  let _iconHasMinEight = <FontAwesomeIcon icon={faTimes} />;
  if (hasMinEight) {
    hasMinEightClass = classes.Ok;
    _iconHasMinEight = <FontAwesomeIcon icon={faCheck} />;
  }

  let hasNumberClass = classes.Error;
  let _iconHasNumber = <FontAwesomeIcon icon={faTimes} />;
  if (hasNumber) {
    hasNumberClass = classes.Ok;
    _iconHasNumber = <FontAwesomeIcon icon={faCheck} />;
  }

  let hasUppercaseClass = classes.Error;
  let _iconHasUppercase = <FontAwesomeIcon icon={faTimes} />;
  if (hasUppercase) {
    hasUppercaseClass = classes.Ok;
    _iconHasUppercase = <FontAwesomeIcon icon={faCheck} />;
  }

  let hasLowercaseClass = classes.Error;
  let _iconHasLowercase = <FontAwesomeIcon icon={faTimes} />;
  if (hasLowercase) {
    hasLowercaseClass = classes.Ok;
    _iconHasLowercase = <FontAwesomeIcon icon={faCheck} />;
  }

  let hasSamePWClass = classes.Error;
  let _iconHasSamePW = <FontAwesomeIcon icon={faTimes} />;
  if (hasSamePW) {
    hasSamePWClass = classes.Ok;
    _iconHasSamePW = <FontAwesomeIcon icon={faCheck} />;
  }

  let classesName = classes.PassWordValidations;
  if (className) classesName += " " + className;
  return (
    <div className={classesName}>
      <p>
        <strong>{t("global.at_last")}</strong>
      </p>
      <p className={hasMinEightClass}>
        {_iconHasMinEight}
        <span>{t("validations.min_8_chars")}</span>
      </p>
      <p className={hasNumberClass}>
        {_iconHasNumber}
        <span>{t("validations.at_last_a_number")}</span>
      </p>
      <p className={hasUppercaseClass}>
        {_iconHasUppercase}
        <span>{t("validations.at_last_a_uppercase")}</span>
      </p>
      <p className={hasLowercaseClass}>
        {_iconHasLowercase}
        <span>{t("validations.at_last_a_lowercase")}</span>
      </p>
      <p className={hasSamePWClass}>
        {_iconHasSamePW}
        <span>{t("validations.pw_not_match")}</span>
      </p>
    </div>
  );
}
