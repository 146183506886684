import React, { useContext } from "react";
import classes from "./Layout.module.scss";
import MainNav from "../Navs/MainNav";
import ScrollToTop from "../../components/ScrollToTop";
import WaterMarkProbrain from "../../components/WaterMarkProbrain";
import Footer from "../../components/Footer";
import UserTrainingContext from "../../context/userTrainingContext";

interface Interface {
  children?: any;
  style?: {
    [propName: string]: any;
  };
  className?: string | null;
}
export default function Layout({
  children,
  style = {},
  className = "",
}: Interface) {
  const tContext = useContext(UserTrainingContext);

  className += " " + classes.Layout;
  if (tContext.game_url) className += " " + classes.NoScroll;

  return (
    <div className={className + " " + classes.Layout}>
      <MainNav />
      <div className={classes.InnerArea}>{children}</div>
      <Footer />
      <WaterMarkProbrain />
      <ScrollToTop />
    </div>
  );
}
