import { useState, useEffect, useCallback, useContext } from "react";
import axios from "axios";
import { Auth } from "aws-amplify";
import SessionContext from "../context/sessionContext";

export default function useFetch({
  url: _url = "",
  method: _method = "GET",
  data: _data = null,
  header: _header = null,
  noAuth: _noAuth = false,
  autoInit: _autoInit = true,
  onFinally: _onFinally = null,
}) {
  const cSession = useContext(SessionContext);

  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const clearError = () => {
    setError(null);
  };
  const request = ({
    url,
    method,
    data,
    headers,
    onError = null,
    onSuccess = null,
    onFinally = null,
  }: any) => {
  
    axios({
      method,
      url,
      headers,
      data,
    })
    
      .then((res) => {
      
        if (method !== "put" && method !== "post") setResponse(res.data);
        if (onSuccess) onSuccess();
      })
      .catch((err) => {
    
        console.error("[error - " + url + "]", err);
        console.error("[error - " + url + "]", err.message);
        if (err.response){
          setError(err.response);
        } 
        else{
          setError(error);
        } 
        if (onError) onError();
      })
      .finally(() => {
        if (onFinally) onFinally();
        setIsLoading(false);
      });
  };
  const fetch = useCallback(
    ({
      url = _url,
      method = _method,
      data = _data,
      header = _header,
      noAuth = _noAuth,
      onError = null,
      onSuccess = null,
      onFinally = null,
    }: any) => {
      setIsLoading(true);
      const auth: any = Auth;
      const _tempData = data;

      if (noAuth || cSession.is_einstein_user) {
        
        let new_header = {...header};
        if(cSession.token_id_session !== "" && cSession.is_einstein_user){
          new_header = {...header,'Authorization' : cSession.token_id_session};
        }
        request({
          method: method,
          url: url,
          headers: {
            ...new_header,
          },
          data: _tempData,
          onError,
          onSuccess,
        });
      } else {
        try {
          Auth.currentAuthenticatedUser()
            .then((cognitoUser) => {
              Auth.currentSession().then((currentSession: any) => {
                cognitoUser.refreshSession(
                  currentSession.refreshToken,
                  (err: any, session: any) => {
                    request({
                      method: method,
                      url: url,
                      headers: {
                        Authorization:
                          auth.user.signInUserSession.idToken.jwtToken,
                        ...header,
                      },
                      data: _tempData,
                      onError,
                      onSuccess,
                      onFinally,
                    });
                  }
                );
              });
            })
            .catch((err) => {
              console.error("[catch error - " + url + "]", err.message);
              if (err.response) setError(err.response);
              else setError(err);
              setIsLoading(false);
            });
        } catch (err: any) {
          console.error("[catch error - " + url + "]", err.message);
          if (err.response) setError(err.response);
          else setError(err);
          setIsLoading(false);
        }
      }
    },
    [_url, _method, _data, _header, _noAuth]
  );

  useEffect(() => {
    if (_autoInit)
      fetch({
        url: _url,
        method: _method,
        data: _data,
        header: _header,
        noAuth: _noAuth,
      });
  }, [_url, _method, _data, _header, _noAuth, _autoInit, fetch]);
  return [response, isLoading, error, fetch, clearError];
}
