import React, { createContext, useEffect, useReducer } from "react";
import { Auth } from "aws-amplify";
import * as kAws from "../constants/aws";
import AES256 from "aes-everywhere";
import { AES_256_KEY } from "../constants/defaults";
import { isTrueFromPythonBoolean } from "../helpers";

interface AdminInterface {
  email: string | null;
  sub: string | null;
  is_manager: boolean | null;
}

export interface UserInterface {
  activity_type?: string | number | null;
  iat?: string | number | null;
  birthdate?: string | null;
  company_name?: string | null;
  company_primary_color?: string | null;
  company_secondary_color?: string | null;
  company_uid?: string | null;
  context?: string | null;
  professional_email?: string | null;
  professional_sub?: string | null;
  registry?: string | null;
  token?: string | null;
  stage?: number | null;
  user_permission?: string
}

interface InitialStateInterface {
  error_msg: string | null;
  company_uid: string | null;
  is_session_active: boolean;
  is_admin: boolean;
  is_user: boolean;
  is_smart_user: boolean;
  token_id_session: string | "";
  is_einstein_user: boolean |false;
  admin: AdminInterface | null;
  user: UserInterface | null;
  [key: string]: any;
}

export interface SessionInterface extends InitialStateInterface {
  setErrorMsg: (msg: string | null) => void;
  setPublicUserBirthdate: (birthdate: string, token: string) => void;
  setPublicUser: (user: any, token: string) => void;
  setAdminUser: (user: any) => void;
  sessionLogout: (msg?: string | null) => void;
}

const SessionContext = createContext({} as SessionInterface);

const SET_ERROR_MSG = "SET_ERROR_MSG";
const SET_PUBLIC_USER = "SET_PUBLIC_USER";
const SET_PUBLIC_SMART_USER = "SET_PUBLIC_SMART_USER";
const SET_PUBLIC_USER_BIRTHDATE = "SET_PUBLIC_USER_BIRTHDATE";
const SET_AUTH_USER = "SET_AUTH_USER";
const SESSION_LOGOUT = "SESSION_LOGOUT";
const USER_SESSION = "user_session";
// const USER_SESSION = AES256.encrypt("user_session", AES_256_KEY);

const reducer = (state: InitialStateInterface, action: any) => {
  const tempSub = action.sub && action.sub !== "" ? action.sub : null;
  switch (action.type) {
    case SET_ERROR_MSG:
      return {
        ...state,
        error_msg: action.msg,
      };
    case SET_PUBLIC_USER:
      return {
        ...state,
        company_uid: action.user && action.user.company_uid,
        user: {
          ...action.user,
          stage: action.user.stage ?? 2,
        },
        error_msg: null,
        is_user: true,
        is_session_active: true,
      };
    case SET_PUBLIC_SMART_USER:
      return {
        ...state,
        company_uid: action.user && action.user.company_uid,
        user: {
          ...action.user,
          stage: action.user.stage ?? 2,
        },
        error_msg: null,
        is_user: true,
        is_smart_user: true,
        is_session_active: true,
      };
    case SET_PUBLIC_USER_BIRTHDATE:
      return {
        ...state,
        company_uid: action.user && action.user.company_uid,
        user: {
          ...state.user,
          token: action.token,
          birthdate: action.birthdate,
        },
      };
    case SET_AUTH_USER:
      return {
        ...state,
        company_uid: action.company_uid,
        admin: {
          company_uid: action.company_uid,
          is_manager: isTrueFromPythonBoolean(action.is_manager),
          email: action.email,
          sub: tempSub,
        },
        error_msg: null,
        is_admin: true,
        is_session_active: true,
        is_einstein_user: (action.is_einstein_user /* || kAws.EINSTEIN_COMPANY_UID==action.company_uid */),
        token_id_session:action.idToken
      };
    case SESSION_LOGOUT:
      return {
        ...state,
        ...action.initialState,
        error_msg: action.msg,
      };
    default:
      return {
        ...state,
      };
  }
};

export const SessionProvider = ({ children }: any) => {
  const initialState: InitialStateInterface = {
    is_session_active: false,
    is_manager: false,
    is_einstein_user: false,
    is_admin: false,
    is_user: false,
    token_id_session: "",
    is_smart_user: false,
    error_msg: null,
    company_uid: null,
    admin: null,
    user: null,
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  // -----------------------------
  //            ACTIONS
  // -----------------------------
  const setErrorMsg = (msg: string | null) => {
    dispatch({
      type: SET_ERROR_MSG,
      msg,
    });
  };
  const setPublicUser = (user: any, token: string) => {
    dispatch({
      type: SET_PUBLIC_USER,
      user: {
        ...user,
        token: token ?? null,
      },
    });
  };
  const setPublicSmartUser = (user: any, token: string) => {
    dispatch({
      type: SET_PUBLIC_SMART_USER,
      user: {
        ...user,
        token: token ?? null,
      },
    });
  };
  const setPublicUserBirthdate = (birthdate: string, token: string) => {
    dispatch({
      type: SET_PUBLIC_USER_BIRTHDATE,
      token,
      birthdate,
    });
  };

  const setAdminUser = (user: any) => {
    // console.log(!!user[kAws.IS_EINSTEIN_USER]);
    dispatch({
      type: SET_AUTH_USER,
      ...user,
      company_uid: user[kAws.CUSTOM_COMPANY_UID],
      is_manager: user[kAws.CUSTOM_IS_MANAGER],
      is_einstein_user: !!(user[kAws.IS_EINSTEIN_USER]),
      email: user.email,
      sub: user.sub,
    });
  };

  const sessionLogout = (msg = null) => {
    Auth.signOut();
    localStorage.setItem(USER_SESSION, "");
    localStorage.setItem("plan_permission", "");
    dispatch({
      type: SESSION_LOGOUT,
      initialState,
      msg,
    });
  };

  useEffect(() => {
    try {
      Auth.currentAuthenticatedUser({ bypassCache: true }).then((user) => {
        if (user && user.attributes) setAdminUser({ ...user.attributes });
      });
    } catch (error) {
      console.error("[Error to clear Cognito]");
    }
  }, []);

  useEffect(() => {
    try {
      const userSession = localStorage.getItem(USER_SESSION);
      if (userSession && userSession !== "") {
        const restoredUser = JSON.parse(
          AES256.decrypt(localStorage.getItem(USER_SESSION), AES_256_KEY)
        );
        if (restoredUser.token && restoredUser.user_permission === "01") setPublicUser(restoredUser, restoredUser.token);
      }
    } catch (err) {
      console.error("[Error to get user_session");
    }
  }, []);

  useEffect(() => {
    try {
      const userSession = localStorage.getItem(USER_SESSION);
      if (userSession && userSession !== "") {
        const restoredUser = JSON.parse(
          AES256.decrypt(localStorage.getItem(USER_SESSION), AES_256_KEY)
        );
        if (restoredUser.token && restoredUser.user_permission === "10") setPublicSmartUser(restoredUser, restoredUser.token);
      }
    } catch (err) {
      console.error("[Error to get user_session");
    }
  }, []);

  useEffect(() => {
    if (state.user) {
      try {
        localStorage.setItem(
          USER_SESSION,
          AES256.encrypt(JSON.stringify(state.user), AES_256_KEY)
        );
      } catch (error: any) {
        console.error("[Error to store user_session", error.message);
      }
    }
  }, [state.user]);

  return (
    <SessionContext.Provider
      value={{
        ...state,
        setErrorMsg,
        setPublicUserBirthdate,
        setPublicUser,
        setPublicSmartUser,
        setAdminUser,
        sessionLogout,
      }}
    >
      {children}
    </SessionContext.Provider>
  );
};
export default SessionContext;
