//  !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
//  !!! IT MUST BE IN LOWER CASE !!!
//  !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
export const PROD = "prod";
export const DEV = "dev";
export const WEB = "web";
export const PASSWORD = "password";
export const SUBMIT = "submit";
export const EMAIL = "email";
export const TEXT = "text";

export const REGISTER = "ID do usuário";
export const ID_COMPANY = "ID da Empresa";
export const ACCESS_KEY = "Chave de acesso";

export const APP_NAME = "AudioFoco";

// Default portal language
export const DEFAULT_LANGUAGE = "pt";

export const AES_256_KEY = "UfcALYQWFtSxdwbsrDHvQ7NvT";

// export const EMAIL_TEMP_FAKE_CHANGE_EMPTY_STRING = "antonio.braz+2@probrain.com.br"
export const EMAIL_TEMP_FAKE_CHANGE_EMPTY_STRING = "";
// export const PASSWORD_TEMP_FAKE_CHANGE_EMPTY_STRING = "TotaLindo123"
export const PASSWORD_TEMP_FAKE_CHANGE_EMPTY_STRING = "";

export const GA_USER_TYPE_ADMIN = "Usuário Admin";
export const GA_USER_TYPE_USER = "Usuário Básico";
export const GA_INFO_NOT_IDENTIFIED = "Não identificado";
