import React from "react";
import classes from "./WaterMarkProbrain.module.scss";
import Logo from "./logo_horizontal.png";
const WaterMarkProbrain = () => {
  return (
    <div
      className={classes.WaterMarkProbrain}
      style={{ backgroundImage: `url(${Logo})` }}
    />
  );
};

export default WaterMarkProbrain;
