export const NEW_PASSWORD_REQUIRED = "NEW_PASSWORD_REQUIRED";

export const TEMP_KEY_USED =
  "Invalid session for the user, session can only be used once.";
export const SESSION_EXPIRED =
  "Invalid session for the user, session is expired.";
export const MAP_ERRORS_TO_SHOW = {
  [TEMP_KEY_USED]: "Você já atualizou a senha, faça login para continuar",
  [SESSION_EXPIRED]: "Sua sessão expirou, faça o login para continuar",
};

export const CUSTOM_COMPANY_UID = "custom:company_uid";
export const CUSTOM_IS_MANAGER = "custom:is_manager";
export const IS_EINSTEIN_USER = "is_einstein_user";
export const EINSTEIN_COMPANY_UID = "HAE001";