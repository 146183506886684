export const getPathArray = (path = "") => {
  const test = Array.from(path.split("/"));
  if (test.length > 0 && test[0] === "") test.shift();
  return test;
};

export const getSimplestPathname = (path = "") => {
  return path.replace(/\/|/g, "");
};

export const get1stPath = (path = "") => {
  const listPath = path.split("/");
  // -> /jogos/jogo/:num
  // -> ["", "jogos", "jogo", :num]
  return listPath[1] ? listPath[1] : null;
};

export const get2ndPath = (path = "") => {
  const listPath = path.split("/");
  // -> /jogos/jogo/:num
  // -> ["", "jogos", "jogo", :num]
  return listPath[2] ? listPath[2] : null;
};

export const get3rdPath = (path = "") => {
  const listPath = path.split("/");
  // -> /jogos/jogo/:num
  // -> ["", "jogos", "jogo", :num]
  return listPath[3] ? listPath[3] : null;
};

export const getQueryStringParams = (query: any) => {
  return query
    ? (/^[?#]/.test(query) ? query.slice(1) : query)
        .split("&")
        .reduce((params: any, param: any) => {
          const [key, value] = param.split("=");
          params[key] = value
            ? decodeURIComponent(value.replace(/\+/g, " "))
            : "";
          return params;
        }, {})
    : {};
};

export const checkValidStringDate = (date: string | null | undefined) => {
  return (
    date && date !== "0000-00-00 00:00:00" && date !== "1969-12-31 21:00:00"
  );
};

export const correctBirthdate = (birthdate: string | null) => {
  let tempData = "";
  if (birthdate && checkValidStringDate(birthdate)) {
    const tempBirthdate = new Date(String(birthdate));
    const tempMonth =
      tempBirthdate.getMonth() + 1 < 10
        ? "0" + (tempBirthdate.getMonth() + 1)
        : tempBirthdate.getMonth() + 1;
    tempData = tempMonth + "/" + tempBirthdate.getFullYear();
  }
  return tempData;
};
export const getSplitedDate = (date:any) => {
  date = date.replace(" ", "T");
  const FormattedDate = new Date(date);
  let day:any = FormattedDate.getDate();
  day = day < 10 ? "0" + day : day;
  // getMonth() return (0 - 11)
  let month:any = FormattedDate.getMonth() + 1;
  month = month < 10 ? "0" + month : month;
  return [day + "/" + month + "/" + FormattedDate.getFullYear()];
};
