// NOT on main nav
export const R_A_FIRST_PW = "/admin/primeira-senha";
// NOT on main nav
export const R_A_ROOT = "/admin";
export const R_A_LOGIN = "/admin/login";
export const R_A_HOME = "/admin/painel";
export const R_A_DASHBOARD = "/admin/painel";
export const R_A_UPDATE_PW = "/admin/senha/atualizar";
export const R_A_REPORT = "/admin/report";
export const R_A_REPORT_SMART = "/admin/smart";
export const R_A_PASSWORD_RECOVERY = "/redefinicao_senha";

export const R_A_USERS_ADD_GO = "/admin/usuarios/cadastrar/go";
export const R_A_USERS_ADD_SMART = "/admin/usuarios/cadastrar/smart";
export const R_A_USERS_OFF = "/admin/usuarios/desativar";
export const R_A_USERS_OFF_GO = "/admin/usuarios/desativar/go";
export const R_A_USERS_OFF_SMART = "/admin/usuarios/desativar/smart";
export const R_A_USERS_LIST = "/admin/usuarios/lista/go";
export const R_A_USERS_LIST_SMART = "/admin/usuarios/lista/smart";
export const R_A_FILTERS_DASHBOARD = "/admin/dashboard";

export const R_A_PRO_ADD = "/admin/admins/cadastrar";
export const R_A_PRO_OFF = "/admin/admins/desativar";
export const R_A_PRO_LIST = "/admin/admins/lista";

export const R_U_ROOT = "/";
export const R_U_LOGIN = "/login";
export const R_U_WELCOME = "/bem-vindo";
export const R_U_HOME = "/treinamento";
export const R_SU_HOME = "/smart";
export const R_U_TRAIN_CAT = "/treinamento/:any";
export const R_U_TRAIN_CAT_SMART = "/smart/treinamento/:any";
export const GET_R_U_TRAIN_CAT = (cat: string) => `/treinamento/${cat}`;
export const GET_R_U_TRAIN_CAT_SMART = (cat: string) => `/smart/treinamento/${cat}`;
export const R_U_TRAIN_GAME_SCREEN = "/treinamento/:any/jogo/:any";
export const GET_R_U_TRAIN_GAME_SCREEN = (cat: string, game: string) =>
  `/treinamento/${cat}/jogo/${game}`;
export const R_U_TESTS = "/testes";
export const R_U_ACTIVE_TEST = "/testes/:any";
export const SET_R_U_TRAIN_TEST = (test: string) => "/testes/" + test;
export const R_U_REQUEST_BIRTHDATE = "/data-nascimento";
export const R_U_TERMS = "";
export const R_U_UPDATE_BIRTHDATE = "/config/data-nascimento";
