import React,{useEffect,useState} from "react";
import classes from "./Footer.module.scss";


const Footer = () => {
 
  const [version, setVersion] = useState(null);

  useEffect(() => {
    fetch('/version.json')
      .then(response => response.json())
      .then(data => {
        setVersion(data.version);
      })
      .catch(error => console.error('Erro ao buscar o arquivo de versão:', error));
  }, []);

  let _content = null;
  // if (scrolled )
  _content = (
    <div className={classes.Footer}>
      <p>
        {
          "Probrain Soluções Neurotecnológicas para Saúde e Educação Ltda | CNPJ 32.841.596/0001-49"
        }
      </p>
      <p>
        {
          "Av. General Carneiro, nº 765, sala 03, Vila Martha,Sorocaba, Estado de São Paulo, 18043-003"
        }
      </p>
      <p>
        Copyright &copy; ProBrain Soluções Neurotecnológicas para Saúde e
        Educação Ltda
      </p>
      <p>
       {version}
      </p>
    </div>
  );
  return _content;
};

export default Footer;
