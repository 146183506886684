import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import Spinner from "./components/Spinner";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import { LoginProvider } from "./context/loginContext";
import { SessionProvider } from "./context/sessionContext";
import { GET_PROD_OR_DEV } from "./constants/api";
import { DEV } from "./constants/defaults";

if (String(GET_PROD_OR_DEV) === String(DEV)) {
  console.log("###############################");
  console.log("######  DEVELOPMENT MODE ######");
  console.log("###############################");
  console.log("--- !!!!!!! CHANGE: !!!!!!! ---");
  console.log("-------- aws-export.js --------");
  console.log("--- !!!!!!! TO PROD !!!!!!! ---");
  console.log("###############################");
  console.log("###############################");
}

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<Spinner centerAbsolute />}>
      <SessionProvider>
        <LoginProvider>
          <I18nextProvider i18n={i18n}>
            <App />
          </I18nextProvider>
        </LoginProvider>
      </SessionProvider>
    </Suspense>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
