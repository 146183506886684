import React, { lazy, useContext, useEffect } from "react";
import classes from "./App.module.scss";
import "./OverrideLibs.scss";
import "./App.module.scss";
import "bootstrap-4-grid/scss/grid.scss";
import Layout from "./hoc/Layout";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import * as r from "./constants/routes";
import * as api from "./constants/api";
import awsConfig from "./aws-exports";
import Amplify, { Auth } from "aws-amplify";
import { createBrowserHistory } from "history";
import SessionContext, { SessionInterface, 
  // UserInterface
 } from "./context/sessionContext";
// main components
import uLogin from "./containers/User/Login";
import aLogin from "./containers/Admin/Login";
import aPasswordRecovery from "./containers/Admin/Login/PasswordRecovery";
import LoginContext from "./context/loginContext";
import { UserTrainingProvider } from "./context/userTrainingContext";
import { checkValidStringDate } from "./helpers";

import ReactPixel, { AdvancedMatching, Options } from "react-facebook-pixel";
import ReactGA from "react-ga";
import { GET_PROD_OR_DEV } from "./constants/api";
import { PROD } from "./constants/defaults";

const isProd = String(GET_PROD_OR_DEV) === String(PROD);

try {
  if (isProd && window.location.protocol !== "https:") {
    window.location.replace(
      `https:${window.location.href.substring(window.location.protocol.length)}`
    );
  }
} catch (error) {
  console.error("[error to set HTTPS] ", error);
}

const uWelcome = lazy(() => import("./containers/SmartUser/Tutorials/Welcome"));
const uHome = lazy(() => import("./containers/User/Home"));
const uFirstEval = lazy(() => import("./containers/SmartUser/Home"));
const uTCategory = lazy(() => import("./containers/User/Training/GamesList"));
const uTGameScreen = lazy(
  () => import("./containers/User/Training/GamesScreen")
);
const uTests = lazy(() => import("./containers/SmartUser/Tests/ListTests"));
const uActiveTest = lazy(() => import("./containers/SmartUser/Tests/Test"));
const uUpdateBirthdate = lazy(
  () => import("./containers/User/Settings/UpdateBirthdate")
);

const aDashboard = lazy(() => import("./containers/Admin/Dashboard"));
const aFiltersDashboard = lazy(() => import("./containers/Admin/FiltersDashboard"));
const aUserList = lazy(() => import("./containers/Admin/Users/User/List"));
const aAdminListSmart = lazy(() => import("./containers/Admin/Users/User/ListSmart"));
const aUserAdd = lazy(() => import("./containers/Admin/Users/User/AddGo"));
const aUserAddSmart = lazy(() => import("./containers/Admin/Users/User/AddSmart"));
const aUserRemove = lazy(() => import("./containers/Admin/Users/User/Remove"));

const aAdminList = lazy(() => import("./containers/Admin/Users/Admin/List"));
const aAdminAdd = lazy(() => import("./containers/Admin/Users/Admin/Add"));
const aAdminReport = lazy(() => import("./containers/Admin/Users/User/Report"));
const aAdminReportSmart = lazy(() => import("./containers/Admin/Users/User/ReportSmart"));
// const aAdminRemove = lazy(
//   () => import("./containers/Admin/Users/Admin/Remove")
// );

const aFirstPW = lazy(() => import("./containers/Admin/Settings/FirstPW"));
const aUpdatePW = lazy(() => import("./containers/Admin/Settings/UpdatePW"));

Amplify.configure(awsConfig);
Auth.configure(awsConfig);
// --------- Google Analytics ----------
const GA_CODE = api.GOOGLE_ANALYTICS;

// --------- Google Analytics ----------
ReactGA.initialize(GA_CODE, { testMode: process.env.NODE_ENV === "test" });
const browserHistory = createBrowserHistory();
browserHistory.listen((location, action) => {
  try {
    ReactGA.pageview(location.pathname + location.search);
  } catch (err) {
    console.error("[err] GA");
  }
});

// --------- Amplify ----------
const advancedMatching = {} as AdvancedMatching; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
const options = {
  autoConfig: true, // set pixel's autoConfig
  // debug: true, // enable logs
} as Options;

// -----------------------------------------------------
// ========== Pixel ====================================
// -----------------------------------------------------
// const ReactPixel = require("react-facebook-pixel");
// ReactPixel.default.init(api.PIXEL_ID, advancedMatching, options);
try {
  ReactPixel.init(api.PIXEL_ID, advancedMatching, options);
  ReactPixel.pageView();
} catch (error) {
  console.error("[pixel]", error);
}

const App = () => {
  const cSession: SessionInterface = useContext(SessionContext);
  const cLogin = useContext(LoginContext);
  // only to refresh the page

  useEffect(() => { }, [cLogin.first_access_res]);
  useEffect(() => { }, [cSession.is_session_active, cSession.admin]);
  useEffect(() => { }, [cSession.user]);

  useEffect(() => {
    const auth: any = Auth;

    // if (!auth.user) {
    // TODO: Verify IF the correct is without "!"
    if (!auth.user && !cSession.is_einstein_user) {
      try {
        Auth.currentAuthenticatedUser().then((cognitoUser:any) => {
          cSession.setAdminUser(cognitoUser.attributes);
        });
      } catch (error: any) {
        console.warn("[Login usuário não Admin]");
      }
    }
  }, [cSession]);

  const hasSession = cSession.is_session_active;
  const isAdminFirstPW = cLogin.first_access_res;
  let isAdmin = false;
  let isUserGo = false;
  let isUserSmart = false;
  const { is_manager: isManager } = cSession.admin ?? {};
  let request_birthdate = false;
  const check_first_avaliation = false;
  let request_first_avaliation = false;
  const check_second_avaliation = false;
  let request_second_avaliation = false;
  const userPermission = cSession.user?.user_permission ?? {};

  if (hasSession) {
    if (cSession.is_admin) isAdmin = true;
    else if (cSession.is_user && cSession.user) {
      if (!checkValidStringDate(cSession.user.birthdate))
      request_birthdate = true;
      else if (check_first_avaliation && userPermission === '10') // "10" smart!!!
      request_first_avaliation = true;
      else if (check_second_avaliation && userPermission === '10') // "10" smart!!!
      request_second_avaliation = true;
      else if (userPermission === '01') isUserGo = true;
      else if (userPermission === '10') isUserSmart = true;
    }
  }

  let _routes = (
    <Switch>
      {/* USER ------------------------------------------------ */}
      {request_birthdate && (
        <Route path={r.R_U_REQUEST_BIRTHDATE} component={uUpdateBirthdate} />
      )}
      {(isUserGo || isUserSmart) && (
        <Route path={r.R_U_WELCOME} component={uWelcome} />
      )}
      {(isUserGo || isUserSmart) && (
        <Route path={r.R_U_TRAIN_GAME_SCREEN} component={uTGameScreen} />
      )}
      {(isUserGo || isUserSmart) && (
        <Route path={r.R_U_TRAIN_CAT} component={uTCategory} />
      )}
      {(isUserGo || isUserSmart) && (
        <Route path={r.R_U_TRAIN_CAT_SMART} component={uTCategory} />
      )}
      {isUserGo && !isUserSmart && (
        <Route path={r.R_U_HOME} component={uHome} />
      )}
      {!isUserGo && isUserSmart && (
        <Route path={r.R_SU_HOME} component={uFirstEval} />
      )}
      {(isUserGo || isUserSmart) && (
        <Route path={r.R_U_UPDATE_BIRTHDATE} component={uUpdateBirthdate} />
      )}
      {isUserGo && !isUserSmart && (
        <Route path={r.R_U_ROOT} exact component={uHome} />
      )}
      {(isUserSmart || request_first_avaliation || isAdmin) && (
        <Route path={r.R_U_TESTS} exact component={uTests} />
      )}
      {(isUserSmart || request_first_avaliation || isAdmin) && (
        <Route path={r.R_U_ACTIVE_TEST} exact component={uActiveTest} />
      )}

      {/* MANAGER ------------------------------------------------ */}
      {/* ADMIN  ------------------------------------------------ */}
      {isAdminFirstPW && <Route path={r.R_A_FIRST_PW} component={aFirstPW} />}
      {isAdmin && <Route path={r.R_A_USERS_LIST} component={aUserList} />}
      {isAdmin && (
        <Route path={r.R_A_USERS_LIST_SMART} component={aAdminListSmart} />
      )}
      {isAdmin && (
        <Route path={r.R_A_FILTERS_DASHBOARD} component={aFiltersDashboard} />
      )}
      {isAdmin && <Route path={r.R_A_USERS_ADD_GO} component={aUserAdd} />}
      {isAdmin && (
        <Route path={r.R_A_USERS_ADD_SMART} component={aUserAddSmart} />
      )}
      {isAdmin && <Route path={r.R_A_REPORT} component={aAdminReport} />}
      {isAdmin && (
        <Route path={r.R_A_REPORT_SMART} component={aAdminReportSmart} />
      )}
      {isAdmin && <Route path={r.R_A_USERS_OFF} component={aUserRemove} />}
      {isAdmin && <Route path={r.R_A_PRO_LIST} component={aAdminList} />}
      {isAdmin && isManager && (
        <Route path={r.R_A_PRO_ADD} component={aAdminAdd} />
      )}
      {isAdmin && <Route path={r.R_A_UPDATE_PW} exact component={aUpdatePW} />}
      {isAdmin && <Route path={r.R_A_DASHBOARD} exact component={aDashboard} />}
      {isAdmin && <Route path={r.R_A_HOME} exact component={aDashboard} />}
      {isAdmin && <Route path={r.R_A_ROOT} exact component={aDashboard} />}

      {/* LOGINS ADMIN ---------------------------------------------------- */}
      {!hasSession && <Route path={r.R_A_LOGIN} exact component={aLogin} />}
      {!hasSession && (
        <Route
          path={r.R_A_PASSWORD_RECOVERY}
          exact
          component={aPasswordRecovery}
        />
      )}
      {!hasSession && <Route path={r.R_A_ROOT} exact component={aLogin} />}
      {/* LOGINS USER ---------------------------------------------------- */}
      {!hasSession && <Route path={r.R_U_LOGIN} exact component={uLogin} />}
      {!hasSession && <Route path={r.R_U_ROOT} exact component={uLogin} />}

      {/* REDIRECT  ------------------------------------------------ */}
      {/* 
        // TODO
      */}
      {request_birthdate && <Redirect to={r.R_U_REQUEST_BIRTHDATE} />}
      {request_first_avaliation && <Redirect to={r.R_U_TESTS} />}
      {request_second_avaliation && <Redirect to={r.R_U_TESTS} />}
      {isAdminFirstPW && <Redirect to={r.R_A_FIRST_PW} />}
      {!isUserGo && isUserSmart && <Redirect to={r.R_SU_HOME} />}
      {(isUserGo || isUserSmart) && !request_first_avaliation && (
        <Redirect to={r.R_U_ROOT} />
      )}
      {isAdmin && <Redirect to={r.R_A_HOME} />}
      {!hasSession && <Redirect to={r.R_U_LOGIN} />}
    </Switch>
  );

  _routes = (
    <Router basename="/">
      <Layout className={classes.App}>{_routes}</Layout>
    </Router>
  );
  if (isUserGo || isUserSmart) _routes = <UserTrainingProvider>{_routes}</UserTrainingProvider>;

  return <React.Fragment>{_routes}</React.Fragment>;
};

export default App;