/*eslint no-restricted-imports: ["error", { paths: [{ name: "foo", importNames: ["DisallowedObject"] }] }]*/
import React, {
  Reducer,
  useCallback,
  useContext,
  useEffect,
  useReducer,
} from "react";
import { AES_256_KEY } from "../../../constants/defaults";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router";
import { useTranslation } from "react-i18next";
import * as a from "../../../constants/api";
import * as d from "../../../constants/defaults";
import * as h from "../../../helpers";
import * as r from "../../../constants/routes";
import AES256 from "aes-everywhere";
import Alert from "../../../components/Alerts";
import classes from "./Login.module.scss";
import InputText from "../../../components/Inputs/InputText";
import logo from "../../../assets/images/favicon.png";
import RoundButton from "../../../components/Buttons/RoundButton";
import SessionContext from "../../../context/sessionContext";
import Spinner from "../../../components/Spinner";
import useFetch from "../../../hooks/useFetch";
import AlertDialog from "../../../components/AlertDialog";
import Crypto from "crypto-js";

const SET_REGISTER = "SET_REGISTER";
const SET_ID_COMPANY = "SET_ID_COMPANY";
const SET_ACCESS_KEY = "SET_ACCESS_KEY";

interface LoginInterface {
  registry: string;
  company_uid: string;
  access_code: string;
}

interface InitialStateInterface extends LoginInterface {
  [key: string]: any;
  auto_login: boolean;
}

const reducer: Reducer<InitialStateInterface, any> = (state, action) => {
  switch (action.type) {
    case SET_REGISTER:
      return {
        ...state,
        registry: action.registry,
      };
    case SET_ID_COMPANY:
      return {
        ...state,
        company_uid: action.company_uid,
      };
    case SET_ACCESS_KEY:
      return {
        ...state,
        access_code: action.access_code,
      };

    default:
      return {
        ...state,
      };
  }
};

export default function Login() {
  // #01 Hooks
  const cSessions = useContext(SessionContext);
  const location = useLocation();
  const history = useHistory();
  const { t } = useTranslation();

  const [user, isLoading, errorFetch, fetchUser]: any = useFetch({
    autoInit: false,
  });

  // #02 Startups
  const initialState: InitialStateInterface = {
    auto_login: false,
    registry: "",
    access_code: "",
    company_uid: "",
  };

  // #03 States
  const [state, dispatch] = useReducer(reducer, initialState);

  const updateRegisterHandler = (value: string) => {
    dispatch({
      type: SET_REGISTER,
      registry: value.toUpperCase(),
    });
  };

  const updateIdCompanyHandler = (value: string) => {
    dispatch({
      type: SET_ID_COMPANY,
      company_uid: value.toUpperCase(),
    });
  };

  const updateAccessKeyHandler = (value: string) => {
    dispatch({
      type: SET_ACCESS_KEY,
      access_code: value.toUpperCase(),
    });
  };

  const loginActionHandler = useCallback(
    (event?: any) => {
      if (
        state.registry !== "" &&
        state.company_uid !== "" &&
        state.access_code !== ""
      ) {
        try {
          fetchUser({
            url: a.API_USER_LOGIN,
            method: "POST",
            data: JSON.stringify({
              registry: AES256.encrypt(
                h.textToServer(state.registry),
                AES_256_KEY
              ),
              company_uid: AES256.encrypt(
                h.textToServer(state.company_uid),
                AES_256_KEY
              ),
              access_code: AES256.encrypt(
                h.textToServer(state.access_code),
                AES_256_KEY
              ),
            }),
            noAuth: true,
          });
        } catch (error: any) {
          console.error("[Error - loginActionHandler] - ", error.message);
        }
      }
    },
    [state.registry, state.company_uid, state.access_code, fetchUser]
  );

  const correctAES256 = (t: string) => {
    return t.replace(/\s+/g, "+") + "=";
  };

  
  useEffect(() => {
    // location.state = plansPath;
    const queryParam:any = new URLSearchParams(location.search);
    let link:any = queryParam.get("link");
    let decryptedData:any={};
    if(link){
      link = decodeURIComponent(link)
      const bytes = Crypto.AES.decrypt(link, a.SECRET_KEY).toString(Crypto.enc.Utf8);
      decryptedData = JSON.parse(bytes.toString(Crypto.enc.Utf8));
    }
    const cID = decryptedData.cid;
    const uID = decryptedData.uid;
    const acess = decryptedData.acess;
    // let app = queryParam.get("app");
    if (cID && uID && acess) {
      try {
        fetchUser({
          url: a.API_USER_LOGIN,
          method: "POST",
          data: JSON.stringify({
            registry: AES256.encrypt(
              h.textToServer(uID),
              AES_256_KEY
            ),
            company_uid: AES256.encrypt(
              h.textToServer(cID),
              AES_256_KEY
            ),
            access_code: AES256.encrypt(
              h.textToServer(acess),
              AES_256_KEY
            ),
          }),
          noAuth: true,
        });
      } catch (error: any) {
        console.error("[Error] - ", error.message);
      }
    }
  }, [location]);

  // ----------------------------------------
  // -------------- useEffects --------------
  // ----------------------------------------
  /*
   http://localhost:3030/?registry=U2FsdGVkX1+ICaCVkfuPhAUK31TvPimwD1XFkEr5olw=&access_code=U2FsdGVkX1/t327o4Oe4H9k2rdpmVlov3VLu0kppZGc=&company_uid=U2FsdGVkX186FPj+eLqMPI/AsrLplvmN85VdtPkuQhA=
  */

  useEffect(() => {
    if (user && user.data && user.token) {
      if (user.data.user_permission === "01") cSessions.setPublicUser(user.data, user.token);
      if (user.data.user_permission === "10") cSessions.setPublicSmartUser(user.data, user.token);
      if (user.birthdate) history.push(r.R_U_WELCOME);
      else history.push(r.R_U_REQUEST_BIRTHDATE);
    }
  }, [user, cSessions, history]);

  useEffect(() => {
    const queries = h.getQueryStringParams(location.search);
    if (queries.registry && queries.company_uid && queries.access_code) {
      fetchUser({
        url: a.API_USER_LOGIN,
        method: "POST",
        data: JSON.stringify({
          registry: correctAES256(queries.registry),
          company_uid: correctAES256(queries.company_uid),
          access_code: correctAES256(queries.access_code),
        }),
        noAuth: true,
      });
    } else {
      // dispatch({
      //   type: SET_LOADING,
      //   loading: false,
      // });
    }
  }, [location.search, fetchUser]);

  // ---------------------------------------
  // -------------- component --------------
  // ---------------------------------------

  let _content = <Spinner center />;
  if (isLoading) {
    _content = <Spinner center />;
  } else {
    let _errorMsg = null;
    if (cSessions.error_msg) {
      _errorMsg = <Alert danger text={t(cSessions.error_msg)} />;
    } else if (errorFetch) {
      _errorMsg = (
        <Alert
          danger
          text={
            errorFetch.status === 403
              ? t("infos.user_not_found_is_all_ok")
              : t("infos.something_wrong_try_again")
          }
        />
      );
      // TODO console.error("");
    }
    _content = (
      <div className={classes.Login}>
        <form
          className={classes.TagCard}
          onSubmit={(e) => {
            e.preventDefault();
            loginActionHandler(e);
          }}
        >
          <div
            className={classes.Logo}
            style={{ backgroundImage: `url(${logo})` }}
          />

          <h1 className={classes.Title}>Bem-vindo!</h1>
          <p className={classes.Subtitle}>Acesse sua conta abaixo</p>
          <InputText
            fake={cSessions.loading}
            label="global.id_company"
            onChange={updateIdCompanyHandler}
            required={true}
            type={d.TEXT}
            value={state.company_uid}
          />
          <InputText
            fake={cSessions.loading}
            label="global.id_user"
            onChange={updateRegisterHandler}
            required={true}
            type={d.TEXT}
            value={state.registry}
          />
          <InputText
            fake={cSessions.loading}
            label="global.access_key"
            onChange={updateAccessKeyHandler}
            required
            type={d.PASSWORD}
            value={state.access_code}
            showPWIcon
          />
          <AlertDialog
            textButton={t("global.wanna_help")}
            isConfirm={false}
            isInfo={true}
            isButtonText={true}
            textDialogPrimary={t("global.wanna_help_info1")}
            textDialogSecondary={t("global.wanna_help_info2")}
          />
          <br />
          {/* <a className={classes.NeedHelp} href={'google.com'}>
            {t("global.wanna_help")}
          </a> */}
          {cSessions.msg_error && !cSessions.loading && (
            <Alert text={cSessions.msg_error} danger />
          )}
          {_errorMsg}
          {cSessions.loading ? (
            <Spinner center />
          ) : (
            <RoundButton
              centerBottom
              huge
              type={d.SUBMIT}
              text={t("global.enter")}
              title={"global.press_to_enter"}
            />
          )}
        </form>
        <div className={classes.termsContainer}>
          <a className={classes.termsLogin} href="https://termos.probrain.com.br/pub/policies/policies_2022.pdf" target="_blank" rel="noreferrer">
            {t("global.policies_and_privileges")}
          </a>
          <> {" "}  - {" "}</>
          <a className={classes.termsLogin} href="https://www.audbility.com.br/_files/ugd/cd0d44_f01a8753e2e647429e39e9bdb3ada60b.pdf" target="_blank" rel="noreferrer">
            {t("global.security_privileges")}
          </a>
          <> {" "}  - {" "}</>
          <a className={classes.termsLogin} href="https://termos.probrain.com.br/pub/terms/terms_2022.pdf" target="_blank" rel="noreferrer">
            {t("global.terms_of_use")}
          </a>
        </div>
      </div>
    );
  }
  return _content;
}
