import React, { useContext, useState } from "react";
import classes from "./Login.module.scss";
import favicon from "../../../assets/images/favicon.png";
import InputText from "../../../components/Inputs/InputText";
import * as h from "../../../helpers";
import RoundButton from "../../../components/Buttons/RoundButton";
import Spinner from "../../../components/Spinner";
import * as d from "../../../constants/defaults";
import { useHistory, useLocation } from "react-router";
import LoginContext from "../../../context/loginContext";
import Alert from "../../../components/Alerts";
import { R_A_USERS_LIST } from "../../../constants/routes";
import { useTranslation } from "react-i18next";
import * as r from "../../../constants/routes";
import { Auth } from "aws-amplify";
import { checkPasswordValidations, checkAllValidations } from "../../../helpers";
import PassWordValidations from "../../../components/PassWordValidations";

export default function PasswordRecovery() {
  // #01 Hooks
  const context = useContext(LoginContext);
  const location = useLocation();
  const { t } = useTranslation();
  const history = useHistory();
  const initialLogin = {
    username: "",
    password: "",
    given_name: "",
    verify_codigo: "",
  };
  // #02 Startups
  const { company_uid } = h.getQueryStringParams(location.search);
  // #03 States
  const [pw, setPw] = useState("");
  const [spinner, setSpinner] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [login, setLogin] = useState(initialLogin);


  const loginActionHandler = (event?: any) => {
    context.authSignInHandler({
      company_uid: company_uid,
      event: event,
      pw: pw,
      history,
      route: R_A_USERS_LIST,
    });
  };

  const [sucessMessage, setSucessMessage] = useState('');
  const [sucessMessageChange, setSucessMessageChange] = useState('');
  const [isSucessCognito, setIsSucessCognito] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [userPWNew, setUserPWNew] = useState({
    email: '',
    acc: '',
    pw: '',
    rePw: ''
  });
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingFirstStep, setIsLoadingFirstStep] = useState(false)


  // INIT RESET PW ACTION HANDLER
  const InitResetPWSubmit = (event: any) => {
    if (event) event.preventDefault();
    if (event) setIsLoadingFirstStep(true);
    Auth.forgotPassword(userPWNew.email)
      .then((res) => {
        setSucessMessage('E-mail enviado com sucesso')
        setErrorMessage('')
        setIsSucessCognito(true)
      })
      .catch((errors) => {
        setSucessMessage('')
        setErrorMessage('Algo deu errado. Por favor, tente mais tarde.')
        setIsLoadingFirstStep(false)
      });
  };
  const resetPWSubmit = (event: any) => {
    if (event) event.preventDefault();
    if (checkAllValidations(userPWNew.pw, userPWNew.rePw)) {
      Auth.forgotPasswordSubmit(
        userPWNew.email,
        userPWNew.acc,
        userPWNew.pw
      )
        .then((res) => {
          setSucessMessageChange('Senha alterada com sucesso! Redirecionando para a página de login...')
          setIsLoading(true);
          setTimeout(() => {
            history.push(r.R_A_ROOT)
          }, 4000)
        })
        .catch((err) => {
          setErrorMessage('Algo deu errado. Por favor, verifique o que foi digitado.')
          setIsLoading(false)
          if (err.code) setErrorMessage('Há um problema com o código')
        })
    } else {
      setErrorMessage('Há um problema com as senhas.')
    }
  }

  return (
    <>
      {!isSucessCognito ?
        <div className={classes.Login}>
          <form className={classes.TagCard} onSubmit={(event) => InitResetPWSubmit(event)}>
            <div
              className={classes.Logo}
              style={{ backgroundImage: `url(${favicon})` }}
            />
            <h1 className={classes.Title}>{t("global.welcome")}!</h1>
            <br />
            <br />
            {errorMessage && <div className={classes.ErrorMsg}>{errorMessage}</div>}
            <p className={classes.Subtitle}>
              {t("global.password_recovery_info")}
            </p>
            <InputText
              label={t("global.email")}
              onChange={(e: any) => setUserPWNew({ ...userPWNew, email: e })}
              required={true}
              type={d.EMAIL}
              value={userPWNew.email}
            />
            <a className={classes.NeedHelp} href="admin/login">
              {t("global.back")}
            </a>
            {context.msg_error && !context.loading && (
              <Alert text={context.msg_error} danger />
            )}
            <RoundButton
              centerBottom
              huge
              disabled={isLoadingFirstStep}
              type={d.SUBMIT}
              text={t("global.send")}
              title={t("global.send")}
            />
          </form>
        </div> :
        <div className={classes.Login}>
          <form className={classes.TagCard} onSubmit={(event) => resetPWSubmit(event)}>
            <div
              className={classes.Logo}
              style={{ backgroundImage: `url(${favicon})` }}
            />
            <h1 className={classes.Title}>{t("global.welcome")}!</h1>
            <br />
            <br />
            {(!sucessMessageChange && !errorMessage && sucessMessage) && <div className={classes.SucessMsg}>{sucessMessage}</div>}
            {sucessMessageChange && <div className={classes.SucessMsg}>{sucessMessageChange}</div>}
            {(errorMessage && !sucessMessageChange) && <div className={classes.ErrorMsg}>{errorMessage}</div>}
            <p className={classes.SubtitlePWRecovery}>
              {t(`Insira o código de confirmação recebido no e-mail 
              ${userPWNew?.email?.split('@')[0]}@${userPWNew?.email?.split('@')[1][0]}... e a nova senha.`)}
            </p>
            <InputText
              label={t("global.verify_code")}
              onChange={(e: any) => setUserPWNew({ ...userPWNew, acc: e })}
              required={true}
              type={d.TEXT}
              value={userPWNew.acc}
            />
            <InputText
              label={t("global.password")}
              onChange={(e: any) => setUserPWNew({ ...userPWNew, pw: e })}
              required={true}
              type={d.PASSWORD}
              value={userPWNew.pw}
              showPWIcon
            />
            <InputText
              label={t("global.rewrite_password")}
              onChange={(e: any) => setUserPWNew({ ...userPWNew, rePw: e })}
              required={true}
              type={d.PASSWORD}
              value={userPWNew.rePw}
              showPWIcon
            />
            <PassWordValidations
              {...checkPasswordValidations(userPWNew.pw, userPWNew.rePw)}
            />
            <a className={classes.NeedHelp} href="admin/login">
              {t("global.back")}
            </a>
            {context.msg_error && !context.loading && (
              <Alert text={context.msg_error} danger />
            )}
            <RoundButton
              centerBottom
              huge
              disabled={isLoading}
              type={d.SUBMIT}
              text={t("global.send")}
              title={t("global.send")}
            />
          </form>
        </div>
      }
    </>
  );
}
