// Login
export const NOT_AUTHORIZED_EXCEPTION = "NotAuthorizedException"; //"Incorrect username or password."
// Verification Code
export const CODE_MISMATCH_EXCEPTION = "CodeMismatchException"; //"Invalid verification code provided, please try again."
export const USER_NOT_CONFIRMED_EXCEPTION = "UserNotConfirmedException"; //"User is not confirmed."
// Signup
export const INVALID_PASSWORD_EXCEPTION = "InvalidPasswordException"; //"Password did not conform with policy: Password not long enough or does not cointain uppercase characters."
export const INVALID_PARAMETER_EXCEPTION = "InvalidParameterException"; //""password  must have length greater than or equal to 6."
export const USERNAME_EXISTS_EXCEPTION = "UsernameExistsException"; //"An account with the given email already exists."
export const LIMIT_EXCEEDED_EXCEPTION = "LimitExceededException"; //"Attempt limit exceeded, please try after some time.""

export const getLoginErrorMsg = (error_code: string | null) => {
  let msg = "";
  switch (error_code) {
    // Login
    case NOT_AUTHORIZED_EXCEPTION:
      msg = "cognito_msg_error.NotAuthorizedException";
      break;
    // Verification Code
    case CODE_MISMATCH_EXCEPTION:
      msg = "cognito_msg_error.CodeMismatchException";
      break;
    case USER_NOT_CONFIRMED_EXCEPTION:
      msg = "cognito_msg_error.UserNotConfirmedException";
      break;
    // singup
    case INVALID_PASSWORD_EXCEPTION:
      msg = "cognito_msg_error.InvalidPasswordException";
      break;
    case INVALID_PARAMETER_EXCEPTION:
      msg = "cognito_msg_error.InvalidParameterException";
      break;
    case USERNAME_EXISTS_EXCEPTION:
      msg = "cognito_msg_error.UsernameExistsException";
      break;
    case LIMIT_EXCEEDED_EXCEPTION:
      msg = "cognito_msg_error.LimitExceededException";
      break;
    default:
      msg = "cognito_msg_error.unidentifiedException";
      break;
  }
  return msg;
};
