import React, { createContext, useContext, useEffect, useReducer } from "react";
import { API_GAMES } from "../constants/api";
import useFetch from "../hooks/useFetch";
import SessionContext from "./sessionContext";

interface InitialStateInterface {
  title: string;
  subtitle: string;
  stage: number;
  isLoading: boolean;
  game_list: { [k: string]: any } | null;
  active_category: string | null;
  game_url: string | null;
  game_name: string | null;
  user_permission: string | null;
  registry: string | null;
  request_first_avaliation: boolean;
  request_second_avaliation: boolean;
}
export interface UserTrainingInterface extends InitialStateInterface {
  stagesData: any;
  steppes: Array<string>;
  setFirstEvaluation: () => void;
  setTraining: () => void;
  setSecondEvaluation: () => void;
  setGameList: (v: { [k: string]: any } | null) => void;
  setActiveCategory: (v: string | null) => void;
  setGameUrl: (v: string | null, v2: string | null) => void;
}

const UserTrainingContext = createContext({} as UserTrainingInterface);

const SET_STAGE_1_FIRST_EVALUATION = "SET_STAGE_1_FIRST_EVALUATION";
const SET_STAGE_2_TRAINING = "SET_STAGE_2_TRAINING";
const SET_STAGE_3_SECOND_EVALUATION = "SET_STAGE_3_SECOND_EVALUATION";
const SET_GAMES_LIST_ERROR = "SET_GAMES_LIST_ERROR";
const SET_GAMES_LIST = "SET_GAMES_LIST";
const SET_ACTIVE_CATEGORY = "SET_ACTIVE_CATEGORY";
const SET_MODAL_GAME_URL = "SET_GAME_MODAL_URL";
const SET_LOADING = "SET_LOADING";

const reducer = (state: InitialStateInterface, action: any) => {
  switch (action.type) {
    case SET_STAGE_1_FIRST_EVALUATION:
      return {
        ...state,
        stage: 1,
        title: action.title,
        subtitle: action.subtitle,
      };
    case SET_STAGE_2_TRAINING:
      return {
        ...state,
        stage: 2,
        title: action.title,
        subtitle: action.subtitle,
      };
    case SET_STAGE_3_SECOND_EVALUATION:
      return {
        ...state,
        stage: 3,
        title: action.title,
        subtitle: action.subtitle,
      };
    case SET_LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
      };
    case SET_GAMES_LIST_ERROR:
      return {
        ...state,
        game_list_error: action.game_list_error,
      };
    case SET_GAMES_LIST:
      return {
        ...state,
        game_list: action.game_list,
      };
    case SET_ACTIVE_CATEGORY:
      return {
        ...state,
        active_category: action.active_category,
      };
    case SET_MODAL_GAME_URL:
      return {
        ...state,
        game_url: action.game_url,
        game_name: action.game_name,
      };
    default:
      return state;
  }
};

export const UserTrainingProvider = ({ children }: any) => {
  const initialState: InitialStateInterface = {
    title: "",
    subtitle: "",
    stage: 0,
    isLoading: false,
    game_list: null,
    active_category: null,
    game_url: null,
    game_name: null,
    user_permission: null,
    registry: null,
    request_first_avaliation: false,
    request_second_avaliation: false
  };
  const cSessions = useContext(SessionContext);

  const { user } = cSessions ?? { user: { token: "" } };
  const [state, dispatch] = useReducer(reducer, initialState);

  let registry = '';
  if (user) registry = user.registry ?? '';
  let user_permission = '';
  const request_first_avaliation = true;
  const request_second_avaliation = false;

  if (user) user_permission = user.user_permission ?? '';
  const [games, isLoading, gamesError, fetchGames]: any = useFetch({
    autoInit: false,
  });

  useEffect(() => {
    if (user && user.token) {
      fetchGames({
        url: API_GAMES,
        noAuth: true,
        header: {
          userauthorization: user.token,
        },
      });
    }
  }, [user, fetchGames]);

  useEffect(() => {
    if (user && user.token && gamesError && gamesError.status === 401) {
      cSessions.sessionLogout("infos.user_not_authenticated");
    }
  }, [cSessions, gamesError, user]);

  const stagesData = {
    1: {
      title: "global.stage_1_title",
      subtitle: "global.stage_1_subtitle",
    },
    2: {
      title: "global.training",
      subtitle: "global.stage_2_subtitle",
    },
    3: {
      title: "global.stage_3_title",
      subtitle: "global.stage_3_subtitle",
    },
  };

  const steppes = ["Primeira avaliação", "Treinamento", "Segunda avaliação"];
  // -----------------------------
  //            ACTIONS
  // -----------------------------

  const setGameListError = (data: { [k: string]: any } | null) => {
    dispatch({
      type: SET_GAMES_LIST,
      game_list_error: data,
    });
  };

  const setLoading = (isLoading: boolean | null) => {
    dispatch({
      type: SET_LOADING,
      isLoading,
    });
  };

  //
  const setFirstEvaluation = () => {
    dispatch({
      type: SET_STAGE_1_FIRST_EVALUATION,
      title: stagesData[1].title,
      subtitle: stagesData[1].subtitle,
    });
  };

  const setTraining = () => {
    dispatch({
      type: SET_STAGE_2_TRAINING,
      title: stagesData[2].title,
      subtitle: stagesData[2].subtitle,
    });
  };

  const setSecondEvaluation = () => {
    dispatch({
      type: SET_STAGE_3_SECOND_EVALUATION,
      title: stagesData[3].title,
      subtitle: stagesData[3].subtitle,
    });
  };

  const setGameList = (data: { [k: string]: any } | null) => {
    dispatch({
      type: SET_GAMES_LIST,
      game_list: data,
    });
  };

  const setActiveCategory = (data: string | null) => {
    dispatch({
      type: SET_ACTIVE_CATEGORY,
      active_category: data,
    });
  };
  const setGameUrl = (url: string | null, game_name: string | null) => {
    const { user } = cSessions ?? { user: { token: null } };
    if (url && user && user.token) url += user.token;
    dispatch({
      type: SET_MODAL_GAME_URL,
      game_url: url,
      game_name: game_name,
    });
  };

  useEffect(() => {
    if (games) setGameList(games);
  }, [games]);

  useEffect(() => {
    if (gamesError) setGameListError(gamesError);
  }, [gamesError]);

  useEffect(() => {
    if (isLoading) setLoading(isLoading);
  }, [isLoading]);

  // -----------------------------
  //            RETURN
  // -----------------------------
  return (
    <UserTrainingContext.Provider
      value={{
        ...state,
        steppes,
        stagesData,
        registry,
        user_permission,
        request_first_avaliation,
        request_second_avaliation,
        // actions ---------------
        setFirstEvaluation,
        setTraining,
        setSecondEvaluation,
        setGameList,
        setActiveCategory,
        setGameUrl,
        // handlers ---------------
      }}
    >
      {children}
    </UserTrainingContext.Provider>
  );
};
export default UserTrainingContext;
