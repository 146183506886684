import React, { useLayoutEffect, useState } from "react";
import classes from "./ScrollToTop.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
const ScrollToTop = () => {
  const { t } = useTranslation();
  const [scrolled, setScrolled] = useState(false);
  const [isNearBottom, setNearBottom] = useState(false);

  const visible = scrolled ? " " + classes.Visible : "";
  const nearBottom = isNearBottom ? " " + classes.Bottom : "";
  useLayoutEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > window.outerHeight / 2);
      const diff =
        document.documentElement.offsetHeight -
        (window.scrollY + window.innerHeight);
      if (window.scrollY > 1) {
        setNearBottom(diff < 15);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  let _content = null;
  // if (scrolled )
  _content = (
    <button
      title={t("global.back_to_top")}
      tabIndex={0}
      className={classes.ScrollToTop + visible + nearBottom}
      onClick={() => {
        window.scrollTo(0, 0);
      }}
    >
      <FontAwesomeIcon icon={faArrowUp} />
    </button>
  );
  return _content;
};

export default ScrollToTop;
