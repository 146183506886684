import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classes from "../Buttons.module.scss";
import { useTranslation } from "react-i18next";

interface Interface {
  title: string;
  // not required
  id?: any;
  text?: string | null;
  aLink?: boolean;
  borderLess?: boolean;
  center?: boolean;
  centerBottom?: boolean;
  className?: string;
  tabIndex?: number;
  disabled?: boolean | undefined;
  extraSmall?: boolean;
  faIcon?: any;
  faIconL?: any;
  full?: boolean;
  huge?: boolean;
  mb?: boolean;
  onClick?: any;
  outline?: boolean;
  isDone?: boolean;
  secondary?: boolean;
  small?: boolean;
  children?: any;
  RightButton?: boolean;
  calendarIcon?: boolean;
  type?: "button" | "submit" | "reset" | undefined;
}

/**
 *
 * @param {*} param0
 * @required title text
 * @returns
 */
export default function RoundButton({
  aLink = false,
  borderLess = false,
  center = false,
  centerBottom = false,
  children = null,
  className = "",
  disabled = undefined,
  extraSmall = false,
  faIcon = undefined,
  faIconL = undefined,
  full = false,
  huge = false,
  mb = false,
  onClick = null,
  outline = false,
  isDone = false,
  secondary = false,
  small = false,
  text = "",
  title = "",
  tabIndex = 0,
  type = undefined,
  RightButton = false,
  calendarIcon = false,
}: Interface) {
  const { t } = useTranslation();
  // Base CSS
  className += " " + classes.BtnRound;
  if (centerBottom) className += " " + classes.CenterBottom;
  if (center) className += " " + classes.Center;
  if (full) className += " " + classes.Full;
  if (RightButton) className += " " + classes.RightButton;

  // Btn Type CSS
  if (secondary) className += " " + classes.Secondary;
  if (outline) className += " " + classes.Outline;
  if (isDone) className += " " + classes.isDone;
  if (borderLess) className += " " + classes.Borderless + " " + classes.Outline;
  if (aLink) className += " " + classes.ALink;
  if (calendarIcon) className += " " + classes.CalendarIcon;

  // Btn Type CSS
  if (extraSmall) className += " " + classes.ExtraSmall;
  if (small) className += " " + classes.Small;
  if (huge) className += " " + classes.Huge;

  // Btn spacings
  if (mb) className += " " + classes.Mb;
  if (!text && !children) className += " " + classes.OnlyIcon;

  // Btn Icons
  let _faIconL = null;
  let _faIcon = null;
  if (faIconL)
    _faIconL = <FontAwesomeIcon className={classes.IconL} icon={faIconL} />;
  if (faIcon)
    _faIcon = <FontAwesomeIcon className={classes.Icon} icon={faIcon} />;

  return (
    <button
      disabled={disabled}
      className={className}
      type={type}
      onClick={onClick}
      title={t(title)}
      tabIndex={tabIndex}
    >
      {_faIconL}
      {text && t(text)}
      {_faIcon}
      {children}
    </button>
  );
}
